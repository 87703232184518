<div
    class="flex grow items-center rounded-md border border-gray-400 bg-transparent text-base focus-within:!border-primary-500 dark:border-gray-500 dark:focus-within:!border-primary-400 has-[:disabled]:opacity-25"
>
    @if (icon) {
        <div class="aspect-square h-6 pl-1 opacity-70">
            <mat-icon [svgIcon]="icon" [inline]="true" />
        </div>
    }
    <!--
        This is hacky, but it's the only way with current Angular.
        See https://github.com/angular/angular/issues/13243
    -->
    @if (type === 'number') {
        <input
            class="box-content min-w-0 grow bg-transparent outline-none"
            [ngClass]="small ? 'h-4 p-1 leading-none' : 'h-6 px-1.5 py-2'"
            [formControl]="control"
            type="number"
        />
    } @else {
        <!-- We use [type]="type" here to avoid breaking non-text types like time. -->
        <input
            class="box-content min-w-0 grow bg-transparent outline-none"
            [ngClass]="small ? 'h-4 p-1 leading-none' : 'h-6 px-1.5 py-2'"
            [formControl]="control"
            [type]="type"
        />
    }

    @if (suffix !== null) {
        <div
            class="font-bold"
            [ngClass]="small ? 'p-1 leading-none' : 'px-1.5 py-2'"
        >
            {{ suffix | translate }}
        </div>
    }
    @if (control.invalid && (control.touched || control.dirty)) {
        <div class="aspect-square h-6 pr-1 text-red-500">
            <mat-icon
                svgIcon="exclamation-thick"
                [matTooltip]="errorMessage | translate"
                [inline]="true"
            />
        </div>
    }
</div>
