<div class="flex flex-row justify-center gap-3 pt-3">
    <sz-button
        size="medium"
        [text]="secondaryText"
        variant="outlined"
        [disabled]="secondaryDisabled"
        (click)="secondaryClick.emit()"
    />

    <sz-button
        size="medium"
        [text]="tertiaryText"
        bgColor="primary"
        variant="outlined"
        [class.!hidden]="tertiaryHidden"
        (click)="tertiaryClick.emit()"
    />

    <sz-button
        size="medium"
        [text]="primaryText"
        [bgColor]="primaryColor"
        [disabled]="primaryDisabled"
        [showSpinner]="showSpinner"
        [class.!hidden]="primaryHidden"
        (click)="handlePrimaryClick()"
    />
</div>
