import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urlBaseV2 } from '../constants';
import { AlertsApi } from './alerts-api';
import { AnnouncementsApi } from './announcements-api';
import { BillingApi } from './billing-api';
import { CanDatabasesApi } from './can-databases-api';
import { ModelDocumentationFilesApi } from './model-documentation-files-api';
import { DashboardsApi } from './dashboards-api';
import { DataFilesApi } from './data-files-api';
import { DevicesApi } from './devices-api';
import { FeedbackApi } from './feedback-api';
import { GeoFencesApi } from './geo-fences-api';
import { HistoricalDataApi } from './historical-data-api';
import { ImagesApi } from './images-api';
import { ModelsApi } from './models-api';
import { ModulesApi } from './modules-api';
import { MqttCommandsApi } from './mqtt-commands';
import { OrganizationsApi } from './organizations-api';
import { OtaFilesApi } from './ota-files-api';
import { PageViewsApi } from './page-views-api';
import { PinsApi } from './pins-api';
import { ReportsApi } from './reports-api';
import { RoadmapEntriesApi } from './roadmap-api';
import { SettingsApi } from './settings-api';
import { UsersApi } from './users-api';
import { CustomWidgetsApi } from './widgets-api';

@Injectable({ providedIn: 'root' })
export class ApiService {
    baseUrl = urlBaseV2;

    alerts = new AlertsApi(this);
    announcements = new AnnouncementsApi(this);
    billing = new BillingApi(this);
    canDatabases = new CanDatabasesApi(this);
    modelDocumentationFiles = new ModelDocumentationFilesApi(this);
    dashboards = new DashboardsApi(this);
    dataFiles = new DataFilesApi(this);
    devices = new DevicesApi(this);
    feedback = new FeedbackApi(this);
    geoFences = new GeoFencesApi(this);
    historicalData = new HistoricalDataApi(this);
    images = new ImagesApi(this);
    models = new ModelsApi(this);
    modules = new ModulesApi(this);
    mqttCommands = new MqttCommandsApi(this);
    organizations = new OrganizationsApi(this);
    otaFiles = new OtaFilesApi(this);
    pageViews = new PageViewsApi(this);
    pins = new PinsApi(this);
    reports = new ReportsApi(this);
    roadmapEntries = new RoadmapEntriesApi(this);
    settings = new SettingsApi(this);
    users = new UsersApi(this);
    widgets = new CustomWidgetsApi(this);

    private dynamicApis = [
        this.alerts,
        this.announcements,
        this.dashboards,
        this.dataFiles,
        this.devices,
        this.geoFences,
        this.models,
        this.modules,
        this.mqttCommands,
        this.organizations,
        this.pins,
        this.reports,
        this.users,
        this.widgets,
    ];

    constructor(public client: HttpClient) {
        this.attachAllTriggers();
    }

    /**
     * Reset all dynamic APIs.
     */
    reset() {
        this.dynamicApis.forEach((api) => {
            api.reset();
        });
    }

    /**
     * Attach triggers for all dynamic APIs.
     */
    attachAllTriggers() {
        this.dynamicApis.forEach((api) => {
            api.attachTriggers();
        });
    }
}
