import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartCanMessage } from 'src/app/chart/models/signals/log';
import { FilterOption } from 'src/app/common/layout/multi-select/multi-select.component';
import { hasRole } from 'src/app/shared/auth-utils';
import { importDateString } from 'src/app/shared/common';
import { Message } from 'src/app/shared/models/can-database';
import {
    CreateDevice,
    Device,
    DeviceSecrets,
    RatePlan,
    SimStatus,
    UptimeRecord,
    getSimStatusName,
    searchFields,
} from 'src/app/shared/models/device';
import {
    ConnectionParams,
    DeviceConnection,
} from 'src/app/shared/models/device-connections';
import { DevicePreview } from 'src/app/shared/models/preview';
import { Stats, StatsParams } from 'src/app/shared/models/stats';
import { environment } from 'src/environments/environment';
import { CustomerRole, DeviceStatus, urlBaseV2 } from '../constants';
import { DateTimeService } from '../date-time.service';
import { ApiService } from './api.service';
import { DynamicApi, ensureLoaded } from './dynamic-api';
import { RawAccessAdapter, createRawAccess } from './raw-access';
import { TopLevelQueryParams, TopLevelRouteApi } from './top-level-route-api';

export interface DevicesQueryParams extends TopLevelQueryParams {
    statusId?: DeviceStatus;
    modelId?: number;
    id?: number;
    customerId?: number;
    'id[ne]'?: number;
}

class RawDevicesApi extends TopLevelRouteApi<
    Device,
    CreateDevice,
    DevicesQueryParams
> {
    get path() {
        return 'devices';
    }

    constructor(api: ApiService) {
        super(api);
    }

    transformBackendData(data): Device {
        return {
            ...data,
            createdDate: importDateString(data.createdDate),
            lastOnline: importDateString(data.lastOnline),
            lastUpdated: importDateString(data.lastUpdated),
        };
    }
}

export class DevicesApi extends DynamicApi<
    Device,
    CreateDevice,
    DevicePreview,
    DevicesQueryParams
> {
    private raw: RawDevicesApi;
    rawAccess: RawAccessAdapter<Device, CreateDevice, DevicesQueryParams>;
    translate: TranslateService;
    dateTimeService: DateTimeService;

    constructor(private api: ApiService) {
        super('devices');
        this.raw = new RawDevicesApi(api);
        this.rawAccess = createRawAccess(this.raw, {
            sortField: 'identifier',
            sortOrder: 'ASC',
            disable: { get: false, create: false, update: false, delete: true },
        });
        this.translate = inject(TranslateService);
        this.dateTimeService = inject(DateTimeService);
    }

    attachTriggers(): void {
        this.api.models.changed$.subscribe(async (model) =>
            this.current({ modelId: model.id }).forEach(({ id }) =>
                this.reloadItem(id),
            ),
        );
    }

    /** Only listen to active devices. */
    listenActive(params: Omit<DevicesQueryParams, 'statusId'> = {}) {
        return this.listen({ ...params, statusId: DeviceStatus.Active });
    }

    /** Only query active devices. */
    queryActive(params: Omit<DevicesQueryParams, 'statusId'> = {}) {
        return this.current({ ...params, statusId: DeviceStatus.Active });
    }

    async loadPreview(id: number): Promise<DevicePreview> {
        const device = await this.getAsync(id);
        const displayName =
            (hasRole(CustomerRole.Dealer)
                ? device.identifier
                : device.userIdentifier) ?? device.identifier;
        const modelName =
            (await this.api.models.getAsync(device.modelId))?.name ??
            this.translate.instant('model.none');
        const organization = await this.api.organizations.getAsync(
            device.customerId,
        );
        const organizationName = organization?.name ?? '';
        const imageUrl = device.modelId
            ? urlBaseV2 + '/models/' + device.modelId + '/thumbnail'
            : environment.apiUrl + '/images/Placeholder-model.png';
        const isActive = device.statusId == DeviceStatus.Active;
        return {
            type: 'device',
            id,
            title: displayName,
            subtitle: modelName,
            imageUrl,
            details: [
                {
                    name: 'common.id',
                    value: id.toString(),
                    role: CustomerRole.Super,
                },
                {
                    name: 'device.serial_number',
                    value: device.serialNumber,
                },
                { name: 'organization', value: organizationName },
                {
                    name: 'status',
                    value: isActive ? 'status.active' : 'status.disabled',
                    icon: isActive ? 'check' : 'access-point-off',
                    color: isActive ? 'success' : 'warn',
                },
                ...(environment.isSpokeZone
                    ? [
                          {
                              name: 'device.sim.status',
                              value: getSimStatusName(device.simStatus),
                          },
                      ]
                    : []),
                {
                    name: 'device.software_version',
                    value: device.primarySoftwareVersion,
                },
                {
                    name: 'device.last_online',
                    value: device.lastOnline
                        ? this.dateTimeService.getRelativeHumanDate(
                              device,
                              false,
                          )
                        : 'common.unknown',
                },
            ],
            item: device,
        };
    }

    async asFilterOption(id: number): Promise<FilterOption> {
        const device = await this.getAsync(id);
        return {
            value: id,
            name: device?.identifier ?? '',
            aliases: searchFields.map((field) => device[field] ?? ''),
        };
    }

    async asFilterOptions(): Promise<FilterOption[]> {
        await ensureLoaded([this]);
        return this.current().map((device) => ({
            value: device.id,
            name: device?.identifier ?? '',
            aliases: searchFields.map((field) => device[field] ?? ''),
        }));
    }

    getAllMessages(id: number) {
        return this.raw.retrieve<(ChartCanMessage & Message)[]>(
            `${id}/all-messages`,
        );
    }

    async getConnectionLog(id: number) {
        return this.raw.retrieveData(`${id}/connection-log`);
    }
    async getSimStatus(id: number) {
        return this.raw.retrieve<SimStatus>(`${id}/sim-status`);
    }

    async getRatePlans() {
        return this.raw.retrieve<RatePlan[]>('rate-plans');
    }

    async getConnections(params?: ConnectionParams) {
        return this.raw.retrieve<DeviceConnection[]>(`connections`, params);
    }

    async getStats(params: StatsParams) {
        return this.raw.retrieve<Stats>('connection-stats', params);
    }

    async getSecrets(id: number) {
        return this.raw.retrieve<DeviceSecrets>(`${id}/secrets`);
    }

    async getUptimeHistory(id: number) {
        return this.raw.retrieve<UptimeRecord[]>(`${id}/uptime-history`);
    }
}
