import {
    booleanAttribute,
    Component,
    HostBinding,
    HostListener,
    Input,
} from '@angular/core';
import { CommonColor, colorClass } from 'src/app/shared/color';
import { isMobile } from 'src/app/shared/screen-size-utils';

export type ButtonSize = 'small' | 'medium' | 'large';

@Component({
    selector: 'sz-icon-button',
    templateUrl: 'icon-button.component.html',
    styles: [':host { display: block }'],
})
export class IconButtonComponent {
    @Input({ required: true }) icon: string;
    @Input({ required: true }) size: ButtonSize = 'small';
    @Input() color: CommonColor = 'default';
    @Input() link: string;
    @Input() queryParams: Record<string, string>;
    @Input() disabled = false;
    @Input({ transform: booleanAttribute }) disableResponsiveSizing = false;

    // This value is updated by the window resize handler below
    isMobile = isMobile();

    private buttonSizeMap = {
        large: 'size-12 rounded-xl',
        medium: 'size-9 rounded-lg',
        small: 'size-6 rounded-md',
    };

    private iconSizeMap = {
        large: 'size-8',
        medium: 'size-6',
        small: 'size-5',
    };

    @HostBinding('style.pointer-events') get pointerEvents(): string {
        return this.disabled ? 'none' : 'auto';
    }

    @HostListener('window:resize')
    onResize() {
        this.isMobile = isMobile();
    }

    get adjustedSize() {
        return this.isMobile && !this.disableResponsiveSizing
            ? 'large'
            : this.size;
    }

    get iconColorClasses() {
        return colorClass(this.color);
    }

    get buttonSizeClasses() {
        return (
            this.buttonSizeMap[this?.adjustedSize] ??
            this.buttonSizeMap['small']
        );
    }

    get iconContainerClasses() {
        return (
            this.iconSizeMap[this?.adjustedSize] ?? this.iconSizeMap['small']
        );
    }
}
